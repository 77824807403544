<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <g id="marker-02">
      <g id="Icon">
        <path
          d="M32 57.6C32 57.6 52.0348 39.7913 52.0348 26.4348C52.0348 15.3699 43.0649 6.40002 32 6.40002C20.9351 6.40002 11.9652 15.3699 11.9652 26.4348C11.9652 39.7913 32 57.6 32 57.6Z"
          stroke="#EA57A8"
          stroke-width="2"
        />
        <path
          d="M38.4008 25.6004C38.4008 29.135 35.5354 32.0004 32.0008 32.0004C28.4662 32.0004 25.6008 29.135 25.6008 25.6004C25.6008 22.0658 28.4662 19.2004 32.0008 19.2004C35.5354 19.2004 38.4008 22.0658 38.4008 25.6004Z"
          stroke="#EA57A8"
          stroke-width="2"
        />
      </g>
    </g>
  </svg>
</template>
