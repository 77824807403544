<template>
  <section class="delivery-modal">
    <ModalPopup
      :show="handler !== null"
      classes="s-delivery-modal"
      @close="handler.cancel()"
    >
      <template #modal-content>
        <div class="fancy-modal flex flex-col relative rounded-2xl text-left py-9 px-[32px] lg:px-8 gap-5">
          <IconPin class="mx-auto block" />
          <h1 class="uppercase text-white headline !text-[20px] lg:text-xl font-bold">
            <slot name="heading" />
          </h1>
          <p class="modal-content p-5">
            <slot name="message" />
          </p>
          <div class="flex flex-col actions">
            <button
              class="text-white py-3 px-[32px] lg:px-10 success rounded-3xl flex items-center hover:!bg-teal transition"
              type="button"
              @click.prevent="handler.confirm()"
            >
              Switch to a New Store
              <IconArrowRight class="w-2 h-2 ml-[32px]" />
            </button>
            <button
              v-if="withCancelButton"
              class="text-sm text-teal underline px-5 font-alt mt-10 transition hover:text-pink"
              type="button"
              @click.prevent.self="handler.cancel()"
            >
              No? Cancel & Go Back
            </button>
          </div>
        </div>
      </template>
    </ModalPopup>
  </section>
</template>

<script>
  import Notice from '@/assets/icon/svg/notice.svg'

  export default {
    name: 'ConfirmModal',
    components: {
      Notice
    },
    props: {
      handler: {
        type: Object,
        default: null
      },
      withCancelButton: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-delivery-modal-container {
    @apply font-alt;
    background-color: #fff;
    background-image: url('/modal-bg.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    @apply mx-auto max-w-5xl;

    select {
      background: url('@/assets/icon/svg/chevron.svg') no-repeat calc(100% - 15px) center;
      background-size: 15px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }

  .s-delivery-modal-step {
    color: #3fb9c5;
    @apply font-alt;
    text-transform: uppercase;
  }

  .s-delivery-modal-h1 {
    color: #000 !important;
    @apply font-main;
    @apply leading-tight font-bold uppercase mb-3 text-2xl md:text-3xl;
  }

  .s-region-modal-h1 {
    color: #000 !important;
    font-size: 1.5rem;
    @apply font-alt;
    @apply leading-tight font-bold mb-3 underline;
  }

  .s-no-delivery-modal-h1 {
    color: #000 !important;
    font-size: 1.5rem;
    @apply font-alt;
    @apply leading-tight font-bold mb-5 underline;
  }

  .s-delivery-buttons-container {
    width: 260px;
  }

  .s-delivery-btn {
    height: 50px;
    border-radius: 25px;
    width: 146px;
    color: #000;
    @apply font-alt;
    border: solid #e5eaed 2px;
  }

  .s-delivery-btn-active {
    background: #002d52;
    color: #fff;
    border: solid #f3b6d0 2px;
    z-index: 10;
  }

  .s-delivery-left-0 {
    left: 0;
  }

  .s-delivery-right-0 {
    right: 0;
  }

  .s-delivery-search-address-svg {
    color: #002d52;
    top: 30%;
  }

  .s-delivery-search-address {
    width: 100%;
    max-width: 375px;
    border-bottom: solid 2px #e5eaed;
    padding-left: 30px;
    @apply font-alt;
    color: #000;
    font-size: 16px;
    @apply py-2;
  }

  .s-delivery-search-address:focus {
    outline: none;
  }

  .s-delivery-search-address::placeholder {
    color: #000;
  }

  .s-delivery-search-result {
    background: #fff;
    color: #000;
    width: 100%;
    max-width: 320px;
    padding: 25px;
    border-radius: 7px;
    border: solid 1px #e5eaed;
    margin: 20px auto auto;
    box-shadow: 0 2px 24px 0 #9ed7f5;
  }

  .s-delivery-search-result-item {
    border-bottom: solid 1px #e7ebee;
    @apply font-alt;
    font-size: 13px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  .s-delivery-modal {
    background: #fff;
    max-width: 1042px;
    height: 576px;
    z-index: 1000;
  }

  .create-link {
    color: #4899b9;
  }

  .fancy-modal {
    @apply w-full max-w-[371px] lg:max-w-[600px] bg-white;
    .modal-content {
      @apply text-black p-5 text-center font-alt text-sm bg-transparent border-0 p-0;
    }
    .actions {
      @apply flex  items-center;
      button {
        @apply font-alt;
        &.success {
          background-color: #fe84b8;
        }
      }
    }
    .headline {
      @apply text-black text-center font-main text-[32px];
    }
    svg {
      @apply relative;
    }
  }
</style>
